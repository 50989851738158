import Input from "form/Inputs/Input";
import useInput from "form/Hooks/user-input";

import Container from "Components/Container/Container";
import { useState, useEffect } from "react";
import Select from "react-select";
// APIs
import useFetch from "Hooks/useFetch";

import Spinner from "Components/RequestHandler/Spinner";
import SubmitModal from "./SubmitModal";
import { SealCheck, SealWarning } from "@phosphor-icons/react";
import ScrollToTop from "Helpers/ScrollToTop";
import ThankYouModal from "./ThankYouModal";

import PDF from "../assests/terms.pdf";

const WarrantyForm = ({
  selectedQuantity,
  onHandleOpen,
  onHandleQuantity,
  onHandleOpenImageModal,
  modalContent,
}) => {
  const [submittedCount, setSubmittedCount] = useState(0);
  const [maxAllowedSubmissions, setmaxAllowedSubmissions] =
    useState(selectedQuantity);
  const [isLoading, setIsLoading] = useState(false);
  const [formIsDone, setFormIsDone] = useState(false);

  const { data, loading, error, fetchData } = useFetch();
  const {
    data: dealerNameData,
    loading: dealerNameLoading,
    error: dealerNameError,
    fetchData: dealerNameFetchData,
  } = useFetch();

  useEffect(() => {
    setmaxAllowedSubmissions(selectedQuantity);
  }, [selectedQuantity]);

  useEffect(() => {
    const storedQuantity = localStorage.getItem("selectedQuantity");
    if (storedQuantity) {
      onHandleQuantity(parseInt(storedQuantity));
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      await fetchData("https://warranty.yokohamalebanon.com/api/tires");

      await dealerNameFetchData(
        "https://warranty.yokohamalebanon.com/api/suppliers"
      );
    };
    getData();
  }, []);

  const [patern, setPatern] = useState(null);

  const handlePaternnWidth = (selectedOption) => {
    setPatern(selectedOption);
    clearErrors();
  };

  const {
    value: serialNumberInput,
    isValid: serialNumberIsValid,
    isTouched: serialNumberIsTouched,
    HasError: serialNumberHasError,
    inputChangeHandler: serialNumberChangeHandler,
    inputBlurHandler: serialNumberBlurHanlder,
    reset: serialNumberReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: emailInput,
    isValid: emailIsValid,
    isTouched: emailIsTouched,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHanlder,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });
  const {
    value: dealerNameInput,
    isValid: dealerNameIsValid,
    isTouched: dealerNameIsTouched,
    HasError: dealerNameHasError,
    inputChangeHandler: dealerNameChangeHandler,
    inputBlurHandler: dealerNameBlurHanlder,
    reset: dealerNameReset,
  } = useInput((value) => value.trim("") !== "");

  // dealer name

  const selectBoxstyle = "bg-white  px-2 py-4 rounded-sm flex-1 w-full";
  const options = dealerNameData?.supplierData.map((name) => ({
    value: name,
    label: name,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      borderRadius: "2px",
      padding: "9px 2px",
      position: "relative",
      zIndex: 0,
    }),
  };

  const clearErrors = () => {
    setSerialNumberError(false);
    setSerialNumberIsEmpty(false);
    SetsubmitClicked(false);
  };

  // warranty code
  const [serialNumberIsLoading, setSerialNumberIsLoading] = useState(false);
  const [serialNumberResponse, setSerialNumberResponse] = useState(null);
  const [serrialNumberError, setSerialNumberError] = useState(false);
  const [serialNumberIsEmpty, setSerialNumberIsEmpty] = useState(false);
  const [serialNumberIsValidate, setSerialNumberIsValidate] = useState(false);

  // terms and condition
  const [termAndConditionIsChecked, setTermAndConditionIsChecked] =
    useState(false);
  const handleTermsAndConditionChange = () => {
    setTermAndConditionIsChecked((prevValue) => !prevValue); // Toggle the checkbox value
  };

  // same tires
  const [isSameTires, setIsSameTires] = useState(false);
  const handleSameSizeTireChange = () => {
    setIsSameTires((prevValue) => !prevValue); // Toggle the checkbox value
  };

  const closeThankYouModalHandler = () => {
    setFormIsDone(false);
  };

  const checkWarrantyCode = async () => {
    clearErrors();
    setSerialNumberIsValidate(false);
    if (!serialNumberIsValid) {
      setSerialNumberIsEmpty(true);
      return;
    }
    try {
      setSerialNumberIsLoading(true);

      const response = await fetch(
        `https://warranty.yokohamalebanon.com/api/validate-serial`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ serial_number: serialNumberInput }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setSerialNumberResponse(data);
      setSerialNumberErrorDublicated(false);
      if (data.is_validate) {
        setSerialNumberIsValidate(true);
      }
    } catch (error) {
      // Handle errors
      setSerialNumberError(true);
    } finally {
      setSerialNumberIsLoading(false);
    }
  };

  useEffect(() => {
    if (serialNumberInput.length === 8) {
      const handler = setTimeout(() => {
        checkWarrantyCode();
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [serialNumberInput]);

  // form
  const [submitClicked, SetsubmitClicked] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);

  useEffect(() => {
    if (
      patern?.value?.length > 0 &&
      serialNumberIsValidate &&
      emailIsValid &&
      termAndConditionIsChecked &&
      dealerNameIsValid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    patern,
    serialNumberIsValidate,
    emailIsValid,
    termAndConditionIsChecked,
    dealerNameIsValid,
  ]);

  const clearsSpecificInputs = () => {
    SetsubmitClicked(false);
    serialNumberReset();
    setSerialNumberResponse(null);
    setSerialNumberIsValidate(false);
  };

  const [submitModalVisible, setSubmitModalVisible] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [updateFormData, setUpdatedFormData] = useState([]);
  const [formDataArray, setFormDataArray] = useState({ data: [] });
  const [serialNumberErrorDuplicated, setSerialNumberErrorDublicated] =
    useState(false);
  const [isGoingBack, setIsGoinBack] = useState(false);

  const createSyntheticEvent = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  const isSerialNumberDuplicate = (serialNumber) => {
    return formDataArray.data.some(
      (form) => form.serial_number === serialNumber
    );
  };

  const nextForm = (e) => {
    e.preventDefault();
    SetsubmitClicked(true);

    if (!formIsValid) {
      return;
    }

    if (!isGoingBack) {
      if (isSerialNumberDuplicate(serialNumberInput)) {
        setSerialNumberErrorDublicated(true);
        return;
      }
    }

    if (!isSameTires) {
      setPatern("");
    }

    setSerialNumberErrorDublicated(false);

    const newFormData = {
      serial_number: serialNumberInput,
      email: emailInput,
      pattern: patern?.value,
      dealerName: dealerNameInput,
    };

    setFormDataArray((prevData) => {
      const updatedData = [...prevData.data];
      updatedData[currentFormIndex] = newFormData; // Update current form data
      return { data: updatedData };
    });

    setIsGoinBack(false);

    setSubmittedCount(submittedCount + 1);
    // serialNumberReset();

    setCurrentFormIndex((prevIndex) => prevIndex + 1);
    clearsSpecificInputs();
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    SetsubmitClicked(true);

    if (!formIsValid) {
      return;
    }

    if (!isGoingBack) {
      if (isSerialNumberDuplicate(serialNumberInput)) {
        setSerialNumberErrorDublicated(true);
        return;
      }
    }

    setSerialNumberErrorDublicated(false);
    const newFormData = {
      serial_number: serialNumberInput,
      email: emailInput,
      pattern: patern?.value,
      dealerName: dealerNameInput,
    };

    setUpdatedFormData({
      data: [...formDataArray.data, newFormData],
    });
    ScrollToTop();
    setTimeout(() => {
      setSubmitModalVisible(true);
    }, 1400);
  };

  const previousForm = (e) => {
    e.preventDefault();
    setIsGoinBack(true);
    if (currentFormIndex === 0) return; // Do nothing if already on the first form

    setSubmittedCount(submittedCount - 1);
    setCurrentFormIndex((prevIndex) => prevIndex - 1);
    const previousFormData = formDataArray.data[currentFormIndex - 1];

    const patternOption = data?.tireData?.product_names.find(
      (option) => option === previousFormData.pattern
    );

    if (patternOption) {
      const patternObject = {
        value: patternOption,
        label: patternOption,
      };
      handlePaternnWidth(patternObject);
    }
    serialNumberChangeHandler(
      createSyntheticEvent("serialNumber", previousFormData.serial_number)
    );
    emailChangeHandler(createSyntheticEvent("email", previousFormData.email));
    dealerNameChangeHandler(
      createSyntheticEvent("dealerName", previousFormData.dealerName)
    );
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    SetsubmitClicked(true);

    if (!formIsValid) {
      return;
    }
    try {
      setIsLoading(true);

      const response = await fetch(
        `https://warranty.yokohamalebanon.com/api/submit-data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateFormData),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        console.log(errorResponse);
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.success) {
        setSubmitModalVisible(false);
        setFormIsDone(true);
        localStorage.removeItem("selectedQuantity");
        clearsSpecificInputs();
        setPatern();
      } else if (!responseData.success) {
        setFormErrorMessage(responseData?.messages[0]);
        serialNumberReset();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const changeQuantity = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
    onHandleOpen();
  };

  return (
    <div className="py-14 md:py-32  overflow-hidden z-[0]">
      <SubmitModal
        errorMessage={formErrorMessage}
        resetErrorMessage={setFormErrorMessage}
        isLoading={isLoading}
        onSubmit={formSubmitHandler}
        onHandleClose={setSubmitModalVisible}
        isOpen={submitModalVisible}
      />
      <Container>
        <form>
          {/* tires info */}
          <div>
            {/* title */}
            <div className="flex flex-col md:flex-row mb-10 md:mb-0 items-center justify-between mt-14">
              <div className="flex flex-col md:flex-row md:items-center mb-4  md:gap-x-4 ">
                <p className="text-white text-2xl font-medium ">
                  Specific tires info
                </p>

                {submittedCount === 0 && (
                  <button
                    onClick={changeQuantity}
                    className="text-white underline mt-1 text-sm text-center lg:text-start"
                  >
                    Change tires Quantity
                  </button>
                )}
              </div>

              <p className="text-white text-xl text-center">
                {submittedCount !== 0 && `You have submitted successfully`}{" "}
                {`${submittedCount} of ${maxAllowedSubmissions} `}{" "}
                {submittedCount !== 0 && "please continue"}
              </p>
            </div>

            {/* size */}
            <div className="flex-1 w-full  mt-2">
              <label className="text-white mb-1 block">Tire Size</label>
              <Select
                options={data?.tireData?.product_names.map((name) => ({
                  label: name,
                  value: name,
                }))}
                value={patern}
                onChange={handlePaternnWidth}
                isSearchable
                placeholder="Search Tire Size"
                styles={customStyles}
              />

              {!patern && submitClicked && (
                <p className={`text-xs text-red-600 mt-1 mb-4 `}>
                  Please select a value.
                </p>
              )}
            </div>
            <div className="flex items-center gap-x-2  mt-2 mb-12">
              <input
                type="checkbox"
                id="same-size-checkbox"
                className="mt-1"
                checked={isSameTires} // Set the checked attribute based on the state
                onChange={handleSameSizeTireChange} // Call handleCheckboxChange when the checkbox is clicked
              />

              <label
                htmlFor="same-size-checkbox"
                className="text-white text-lg"
              >
                Same size tires
              </label>
            </div>
            {/* serrial */}
            <div>
              <span className="flex gap-y-1 flex-col  w-full">
                {serialNumberErrorDuplicated && (
                  <p className="text-red-600">
                    You Can't use Same Warranty code
                  </p>
                )}
                {serialNumberResponse && (
                  <p
                    className={` mb-2 flex items-center gap-x-2 ${
                      serialNumberResponse?.is_validate
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {serialNumberResponse.message}
                    {serialNumberResponse?.is_validate && (
                      <SealCheck size={24} />
                    )}

                    {!serialNumberResponse?.is_validate && (
                      <SealWarning size={24} />
                    )}
                  </p>
                )}

                {serialNumberIsEmpty && (
                  <p className="text-red-600 text-lg mb-2">
                    Please provide a valid warranty code
                  </p>
                )}

                {serrialNumberError && (
                  <p className="text-red-600  mb-2">
                    Server Error, please try again later. If the error persists,
                    please contact us at{" "}
                    <a
                      className="underline"
                      href="mailto:info@yokohamalebanon.com"
                    >
                      info@yokohamalebanon.com
                    </a>
                  </p>
                )}

                <label className="text-white block mb-1">
                  Your warranty code
                </label>

                <div className="w-full bg-white px-4 py-4 rounded-sm flex items-center justify-between">
                  <input
                    className={"w-3/4"}
                    id={"serialNumber"}
                    type={"text"}
                    name={"serialNumber"}
                    placeholder={"Your warranty code"}
                    value={serialNumberInput}
                    onChange={(e) => {
                      serialNumberChangeHandler(e);
                      clearErrors();
                    }}
                    // readOnly={serialNumberResponse?.is_validate ? true : false}
                    onBlur={serialNumberBlurHanlder}
                  />

                  {serialNumberIsLoading && <Spinner />}
                  {!serialNumberIsLoading && (
                    <button className="" onClick={checkWarrantyCode}>
                      Check
                    </button>
                  )}
                </div>
              </span>

              <p
                className={` text-xs text-red-600 mt-1 mb-4 ${
                  (submitClicked && !serialNumberIsValid) ||
                  (submitClicked && !serialNumberIsValidate)
                    ? "opacity-1 "
                    : "opacity-0"
                }`}
              >
                Warranty code, is not valid
              </p>
            </div>
          </div>
          {/* general info */}
          {submittedCount < 1 && (
            <div>
              <p className="text-white text-2xl font-medium mb-6">
                General info
              </p>
              {/* <div className="flex flex-col md:flex-row items-center gap-x-4">
              <Input
                boxStyle={"w-full"}
                label={"First Name (الاسم الأول)"}
                type={"text"}
                id={"firstName"}
                value={firstNameInput}
                placeholder="First Name (الاسم الأول)"
                onChange={(event) => {
                  firstNameChangeHandler(event);
                  clearErrors();
                }}
                onBlur={firstNameBlurHanlder}
                hasError={
                  firstNameHasError || (!firstNameIsTouched && submitClicked)
                }
                errorMessage={"This field is required "}
              />
              <Input
                boxStyle={"w-full"}
                type={"text"}
                label="Last Name (اسم العائلة)"
                id={"lastName"}
                value={lastNameInput}
                placeholder="Last Name (اسم العائلة)"
                onChange={(event) => {
                  lastNameChangeHandler(event);
                  clearErrors();
                }}
                hasError={
                  lastNameHasError || (!lastNameIsTouched && submitClicked)
                }
                onBlur={lastNameBlurHanlder}
                errorMessage={"This field is required"}
              />
            </div> */}
              <div className="flex flex-col lg:flex-row items-center gap-x-6">
                <Input
                  boxStyle={"w-full"}
                  type={"email"}
                  id={"email"}
                  label="Email Address (e.g. john@example.com)  (البريد الإلكتروني)"
                  value={emailInput}
                  placeholder="Email Address (e.g. john@example.com)  (البريد الإلكتروني)"
                  onChange={(event) => {
                    emailChangeHandler(event);
                    clearErrors();
                  }}
                  hasError={emailHasError || (!emailIsTouched && submitClicked)}
                  onBlur={emailBlurHanlder}
                  errorMessage={"This field is required"}
                />
                <Input
                  boxStyle={"w-full "}
                  type={"text"}
                  id={"DealerName"}
                  label={"Dealer Name"}
                  value={dealerNameInput}
                  placeholder={"Dealer Name"}
                  onChange={(event) => {
                    dealerNameChangeHandler(event);
                    clearErrors();
                  }}
                  onBlur={dealerNameBlurHanlder}
                  hasError={
                    dealerNameHasError ||
                    (!dealerNameIsTouched && submitClicked)
                  }
                  errorMessage={"This field is required"}
                />
              </div>
              {/* <div className="flex flex-col md:flex-row items-center gap-x-4 ">
              <div className="flex-1 w-full lg:mb-[2px]">
                <label className="text-white mb-1 block">
                  Search Dealer Name
                </label>
                <Select
                  options={options}
                  onChange={handleDealerName}
                  isSearchable
                  placeholder="Search Dealer Name"
                  styles={customStyles}
                />
                <p
                  className={`text-xs text-red-600 mt-1 mb-4 ${
                    !dealderName && !OtherDealerNameIsValid && submitClicked
                      ? "opacity-1"
                      : "opacity-0"
                  }`}
                >
                  Please select a dealer name from the dropdown or specify
                  'Other'.
                </p>
              </div>
              <div className="flex-1 w-full">
                <Input
                  boxStyle={"w-full "}
                  type={"text"}
                  id={"otherDealerName"}
                  label={"(Other) آخر "}
                  value={OtherDealerNameInput}
                  placeholder={"(Other) آخر "}
                  onChange={(event) => {
                    OtherDealerNameChangeHandler(event);
                    clearErrors();
                  }}
                  onBlur={OtherDealerNameBlurHanlder}
                  hasError={
                    OtherDealerNameHasError ||
                    (!OtherDealerNameIsTouched &&
                      submitClicked &&
                      (!dealderName || OtherDealerNameIsValid))
                  }
                  errorMessage={
                    "Please select a dealer name from the dropdown or specify 'Other'. "
                  }
                />
              </div>
            </div> */}
            </div>
          )}

          <div className="flex flex-col lg:flex-row gap-y-6 lg:items-center justify-between mt-10">
            <div className="">
              <div className="flex items-center gap-x-4">
                <input
                  className="w-5 h-5"
                  type="checkbox"
                  id="terms-checkbox"
                  checked={termAndConditionIsChecked} // Set the checked attribute based on the state
                  onChange={handleTermsAndConditionChange} // Call handleCheckboxChange when the checkbox is clicked
                />
                <label htmlFor="terms-checkbox" className="text-white text-lg">
                  I have read and accepted the{" "}
                  <a
                    href={PDF}
                    rel="noreferrer"
                    target="_blank"
                    className="text-red-500 underline"
                  >
                    warranty terms and conditions
                  </a>
                </label>
              </div>

              {submitClicked && !termAndConditionIsChecked && (
                <p className={`text-red-500 text-sm my-1 `}>
                  Please accept the terms and conditions
                </p>
              )}
            </div>
            <div className="flex items-center gap-x-8">
              <button
                className={`bg-gray-500 text-white px-10 py-3 mt-4 rounded-md  items-center gap-x-2 ${
                  currentFormIndex === 0 ? "hidden" : "flex"
                }`}
                onClick={previousForm}
              >
                Back
              </button>
              {submittedCount === maxAllowedSubmissions - 1 ? (
                <div className="">
                  <button
                    className="bg-red-800 text-white px-10 py-3 mt-4 rounded-md flex items-center gap-x-2"
                    onClick={formSubmit}
                  >
                    {isLoading && <Spinner />} {!isLoading && "Submit"}
                  </button>
                </div>
              ) : (
                <div className="flex gap-4">
                  <button
                    className="bg-red-800 text-white px-10 py-3 mt-4 rounded-md flex items-center gap-x-2"
                    onClick={nextForm}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>

        <ThankYouModal
          onHandleClose={closeThankYouModalHandler}
          data={modalContent}
          // isVisible={true}
          isVisible={formIsDone}
        />
      </Container>
    </div>
  );
};

export default WarrantyForm;
