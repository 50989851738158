import Header from "Sections/Header";
import Banner from "Sections/Banner";
import WelcomeModal from "Sections/WelcomeModal";
import WarrantyForm from "Sections/WarrantyForm";
import ImageModal from "Sections/ImageModal";
import React, { useState, useEffect } from "react";
// images
import rimSize from "assests/Rim-Size.jpg";
import ratio from "assests/Aspect-Ratio.jpg";
import width from "assests/Rim-Size.jpg";

import useFetch from "Hooks/useFetch";

const App = () => {
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [imageModalIsActive, setImageModalIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const { data, loading, erro, fetchData } = useFetch("");

  const imagesData = [
    {
      id: 1,
      image: width,
    },
    {
      id: 2,
      image: ratio,
    },
    {
      id: 3,
      image: rimSize,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      await fetchData("https://warranty.yokohamalebanon.com/api/get-info");
    };

    getData();
  }, []);

  const handleSelectQuantity = (quantity) => {
    setSelectedQuantity(quantity);
  };

  const onHandleClose = () => {
    setIsOpen(false);
  };

  const onHandleOpen = () => {
    setIsOpen(true);
  };

  const openImageModalHandler = (id) => {
    const selectedImg = imagesData.find((image) => image.id === id);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      setImageModalIsActive(true);
      setSelectedImage(selectedImg.image);
    }, 500);
  };
  const closeImageModalHandler = () => {
    setImageModalIsActive(false);
  };

  const welcomeMessageData = {
    title: data?.info_data?.welcome_message_title,
    subTitle: data?.info_data?.welcome_message_sub_title,
    text: data?.info_data?.welcome_message_text,
  };
  const thankYouMessageData = {
    title: data?.info_data?.thankyou_message_title,
    text: data?.info_data?.thankyou_message_text,
  };

  return (
    <div className="App bg-black ">
      <div className="overflow-hidden">
        <WelcomeModal
          data={welcomeMessageData}
          onHandleQuantity={handleSelectQuantity}
          guantity={selectedQuantity}
          onHandleClose={onHandleClose}
          isOpen={isOpen}
        />
        <Header />
        <Banner image={data?.info_data?.site_banner} />
        <WarrantyForm
          onHandleClose={onHandleClose}
          onHandleOpen={onHandleOpen}
          selectedQuantity={selectedQuantity}
          onHandleQuantity={handleSelectQuantity}
          onHandleOpenImageModal={openImageModalHandler}
          onHandleCloseImageModal={closeImageModalHandler}
          modalContent={thankYouMessageData}
        />
        <ImageModal
          isActive={imageModalIsActive}
          onhandleClose={closeImageModalHandler}
          selectedImage={selectedImage}
        />
      </div>
    </div>
  );
};

export default App;
