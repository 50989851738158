import React from "react";
import image from "assests/how-to.jpg";

import { useEffect } from "react";
const ImageModal = ({ isActive, onhandleClose, selectedImage }) => {
  useEffect(() => {
    // Enable or disable scrolling based on the modal visibility
    if (isActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up by restoring the default overflow property when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isActive]);

  return (
    <div className={isActive ? "block" : "hidden"}>
      <div className="absolute  w-[100vw] h-[100vh] bg-[#0000009d] left-0 top-0 "></div>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[90vw] lg:w-[70vw] lg:h-[24rem]">
        <button
          onClick={onhandleClose}
          className="bg-red-600 text-white rounded-md px-10 py-2 mx-auto w-max flex items-center justify-center mb-10"
        >
          CLose
        </button>
        <img
          className="w-full h-full object-contain"
          src={selectedImage}
          alt=""
        />
      </div>
    </div>
  );
};

export default ImageModal;
