import React from "react";

const Input = (props) => {
  const {
    boxStyle,
    inputStyle,
    type,
    name,
    id,
    label,
    placeholder,
    value,
    onChange = () => {},
    onBlur = () => {},
    hasError = false,
    errorMessage = "error",
  } = props;

  return (
    <span className={`flex gap-y-1 flex-col  w-full `}>
      <label className="text-white mb-1 text-sm lg:text-base">{label}</label>
      <input
        className={`bg-white  px-2 py-4 rounded-sm ${
          hasError ? "border-2 border-red-700" : ""
        } }`}
        id={id}
        type={type}
        name={name}
        // placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />

      <p
        className={`text-xs text-red-600 mt-1 mb-4  ${
          hasError ? "opacity-1 " : "opacity-0"
        }`}
      >
        {errorMessage}
      </p>
    </span>
  );
};

export default Input;
