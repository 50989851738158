import React, { useState, useEffect } from "react";
import Spinner from "Components/RequestHandler/Spinner";
const SubmitModal = ({
  isOpen,
  onSubmit,
  isLoading,
  onHandleClose,
  errorMessage,
  resetErrorMessage,
}) => {
  useEffect(() => {
    // Enable or disable scrolling based on the modal visibility
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up by restoring the default overflow property when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const goBackHandler = () => {
    resetErrorMessage("");
    onHandleClose(false);
  };

  const buttonStyle =
    "px-8 py-2 text-lg font-medium rounded-md bg-red-700 text-white flex-auto hover:bg-red-700 hover:text-white transition ease-in duration-300 mt-12 flex items-center gap-x-2 justify-center mx-auto";

  return (
    isOpen && (
      <>
        <div className="fixed bg-[#000000de] top-0 left-0 z-[10000] w-[100vw] h-[100vh]"></div>
        <div className="fixed w-[90%] xl:w-auto mx-auto left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-6 lg:p-24 text-center z-[10000]">
          <h1 className="text-lg font-medium">
            Are you sure you want to submit the codes? Once a warranty code is
            submitted it cannot be used again , please double check your action
            before submitting
          </h1>
          {errorMessage && (
            <p className="text-red-600 mt-10 text-center">{errorMessage}</p>
          )}
          <div className="flex items-center gap-x-8">
            <button onClick={onSubmit} className={buttonStyle}>
              {isLoading && <Spinner />}
              Submit
            </button>
            <button
              onClick={() => goBackHandler(false)}
              className={
                "px-8 py-2 text-lg font-medium rounded-md border border-red-400 flex-auto hover:bg-red-700 hover:text-white transition ease-in duration-300 mt-12 flex items-center gap-x-2 justify-center mx-auto"
              }
            >
              Back
            </button>
          </div>
        </div>
      </>
    )
  );
};

export default SubmitModal;
