import Container from "Components/Container/Container";
import logo from "assests/logo.png";
const Header = () => {
  return (
    <header className="bg-black py-6">
      <Container>
        <div className="flex flex-col gap-5 md:flex-row md:items-center md:justify-between">
          <div>
            <img src={logo} alt="" />
          </div>

          <div className="flex items-end justify-end gap-x-10">
            <a
              className="text-white"
              rel="noreferrer"
              target="_blank"
              href="https://www.yokohamalebanon.com/contactus"
            >
              Contact us
            </a>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
