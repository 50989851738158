import React, { useState, useEffect } from "react";

const WelcomeModal = ({ onHandleQuantity, isOpen, onHandleClose, data }) => {
  useEffect(() => {
    // Enable or disable scrolling based on the modal visibility
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up by restoring the default overflow property when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const [selectedQuantity, setSelectedQuantity] = useState(null);

  useEffect(() => {
    const storedQuantity = localStorage.getItem("selectedQuantity");
    if (storedQuantity) {
      setSelectedQuantity(parseInt(storedQuantity));
      onHandleClose();
    }
  }, []);

  const buttonStyle =
    "px-8 py-2 text-lg font-medium rounded-md border border-red-400 flex-auto hover:bg-red-700 hover:text-white transition ease-in duration-300";
  const activeButtonStyle =
    "px-8 py-2 text-lg font-medium rounded-md bg-red-700 text-white flex-1";

  const handleCloseModal = () => {
    onHandleClose();
  };

  const handleSelectQuantity = (quantity) => {
    localStorage.setItem("selectedQuantity", quantity);
    setSelectedQuantity(quantity);
    onHandleQuantity(quantity);
    handleCloseModal();
  };

  const renderButton = (quantity) => {
    const isActive = quantity === selectedQuantity;
    const style = isActive ? activeButtonStyle : buttonStyle;

    return (
      <button
        key={quantity}
        className={style}
        onClick={() => handleSelectQuantity(quantity)}
      >
        {quantity}
      </button>
    );
  };

  return (
    isOpen && (
      <>
        <div className="fixed bg-[#000000c2] z-[10000] top-0 left-0 max-w-[100vw] min-w-[100vw] h-[100vh]"></div>
        <div className="fixed w-[90%] xl:w-auto mx-auto left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-6 lg:p-24 text-center z-[10000]">
          <h1 className="text-2xl font-bold mb-2">{data.title}</h1>
          <p>{data.subTitle}</p>

          <div className="mt-10">
            <p>{data.text}</p>
            <div className="grid grid-cols-2 lg:grid-cols-4 items-center justify-between gap-4 mt-6 ">
              {[1, 2, 3, 4].map(renderButton)}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default WelcomeModal;
