import { useEffect } from "react";

const ThankYouModal = ({ data, onHandleClose, isVisible }) => {
  useEffect(() => {
    // Enable or disable scrolling based on the modal visibility
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up by restoring the default overflow property when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);
  return (
    <div className={`${isVisible ? "block" : "hidden"} `}>
      <div className="fixed bg-[#000000de] top-0 left-0 z-[100] w-[100vw] h-[100vh]"></div>
      <div className="fixed w-[90%] xl:w-auto mx-auto left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-6 lg:p-24 text-center z-[10000]">
        <div className="p-6 lg:px-16 lg:pb-16 text-center">
          <h1 className="text-2xl font-bold mb-2">{data.title}</h1>
          <p>{data.text}</p>

          <p>An email has been sent to you.</p>

          <div className="flex items-center gap-x-10 justify-center mt-6">
            <button className="border border-red-600 px-6 py-2 rounded-md transition ease-in duration-300 hover:bg-red-600 hover:text-white">
              <a href="https://www.yokohamalebanon.com/"> Home</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouModal;
